#project {
    //@include section-css;
    flex-direction: column;
    h3 {
        text-align: center;
        color: $white-opac;
        margin: 20px;
        font-size: 1.3em;
    }
    .container {
        display: flex;
        justify-content: space-around;
        font-size: 1.5em;
        //height: 90dvh;
        width: 100%;
        .project-container {
            //height: fit-content;
            //width: 94.5%;
            display: flex;
            align-items: center;
            justify-content: center;
            .project-slide {
                height: 83.5%;
                width: 70%;
                background-color: $primary;
                border-radius: 10px;
                border: 3px solid $primary;

                .desc-grid {
                    display: flex;
                    justify-content: space-between;
                    height: 10%;
                    width: 100%;
                    .name {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding-left: 1rem;
                    }
                    .link {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-right: 1rem;
                        height: 40px;
                        width: 40px;
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            width: 40px;
                            text-align: center;
                            color: $white-opac;
                        }
                        a:hover {
                            cursor: pointer;
                            color: $white;
                        }
                    }
                }
                .img-grid {
                    height: 90%;
                    width: 100%;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                        aspect-ratio: 3/2;
                    }
                }
            }
        }
    }
}
