#github-project {
    @include margin;
    h3 {
        text-align: center;
        color: $white-opac;
        margin: 20px;
    }
    .loader-container {
        display: flex;
        justify-content: center;
    }
    .pj-error {
        width: 80%;
        margin: 0 auto;
        font-size: 1.5rem;
        text-align: center;
    }
    .container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .repo-card {
            width: 300px;
            min-height: 200px;
            border-radius: 10px;
            margin: 10px;
            border: 2px solid $primary;
            //box-shadow: 10px 10px $primary-opac;
            .heading-container {
                h4 {
                    padding: 5px;
                    color: $primary;
                }
                padding: 5px;
                display: flex;
                justify-content: space-between;
                .date {
                    color: $white-opac;
                }
            }
            .details-container {
                font-size: 0.9em;
                margin-top: 15px;
                min-height: 136px;
                padding: 5px;
                .language {
                    padding-top: 20px;
                }
            }
            .card-footer {
                display: flex;
                justify-content: right;
                align-items: center;
                border-radius: 0 0 6px 6px;
                height: 40px;
                background-color: $primary;
                span {
                    color: $white-opac;
                    padding: 15px;
                }
                span:hover {
                    color: $white;
                    transition: 0.6s;
                }
            }
        }
        .repo-card:hover {
            box-shadow: 10px 10px $primary-opac;
            transition: 0.4s;
        }
    }
}
.light {
    #project {
        .container {
            .repo-card {
                background-color: rgba(143, 143, 143, 0.275);
                border: 2px solid $primary-lightMode;
                .heading-container {
                    h4 {
                        color: $primary-lightMode;
                    }
                }
                .card-footer {
                    background-color: $primary-lightMode;
                }
            }
        }
        .repo-card:hover {
            box-shadow: 10px 10px $primary-opac-lightMode;
        }
    }
}
