#footer {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $primary-opac;
    height: 200px;
    padding: 0;
    p {
        font-size: 1.4rem;
        padding-bottom: 25px;
    }
    .link-container {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 35px;
        border: solid 3px;
        border-radius: 10px;
    }
    a {
        align-self: center;
        text-decoration: none;
        color: $white;
        font-size: 1.4em;
        font-weight: bold;
    }
    .link-container:hover {
        background: $white;
        color: $primary;
    }
    .outer-footer {
        text-align: center;
        width: 100%;
        .copyright {
            font-size: 1em;
            color: $white;
            a {
                font-size: 1em;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        padding-top: 20px;
        padding-bottom: 10px;
    }
}
.light{
    #footer {
    background: $primary-opac-lightMode;
        .link-container:hover {
        color: $primary-lightMode;
    }
    }
}