//This is the style for the whole education section
#education {
    @include margin;
    h4 {
        color: $white;
        padding: 15px;
    }

    .container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        color: $primary;
        .left-container {
            border-right: 5px solid $primary;
            width: 45%;

            .education-cont {
                transition: all ease-in 0.2s;
                border-radius: 10px;
                margin: 30px auto;
                width: 75%;
                min-height: 250px;
                background: rgba(255, 255, 255, 0.148);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                span {
                    padding: 10px;
                }
            }
            .education-cont:hover {
                box-shadow: 0 10px 10px $primary-opac;
                border: solid $primary-opac 1px;
            }
        }
        .right-container {
            border-left: 5px solid $primary;
            width: 45%;

            .exp-container {
                transition: all ease-in 0.2s;
                border-radius: 5px;
                width: 75%;
                margin: 30px auto;
                background: $primary-opac;
                display: flex;
                flex-direction: column;
                justify-content: left;
                align-items: center;

                .img-container {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img {
                        padding: 10px;
                        height: 50px;
                        width: auto;
                    }
                }
                .text-container {
                    padding: 10px;
                    h4,
                    p {
                        color: $white;
                        text-align: center;
                    }
                    ul {
                        margin: 20px;
                    }
                    width: 90%;
                    p {
                        padding: 10px;
                    }
                    span {
                        color: $white-opac;
                        padding: 0 10px;
                    }
                    .date {
                        color: $primary;
                    }
                    .tools {
                        display: flex;
                        flex-flow: row wrap;
                        font-size: 0.8em;
                    }
                }
            }
            .exp-container:hover {
                box-shadow: 0 10px 10px $white-opac;
                border: solid $white-opac 1px;
            }
        }
    }
}
.light {
    #education {
        .container {
            color: $primary-lightMode;
            .left-container {
                border-right: 5px solid $primary-lightMode;
                .education-cont {
                    background-color: rgba(143, 143, 143, 0.483);
                }
                  .education-cont:hover {
                box-shadow: 0 10px 10px $primary-opac-lightMode;
                border: solid $primary-opac-lightMode 1px;
            }
            }
            .right-container {
                border-left: 5px solid $primary-lightMode;
                .exp-container {
                    background: $primary-opac-lightMode;
                    .text-container {
                        .date {
                            color: $primary-lightMode;
                        }
                    }
                }
                .exp-container:hover {
                    box-shadow: 0 10px 10px $grey;
                    border: solid rgba(128, 128, 128, 0.677) 1px;
                }
            }
        }
    }
}
