
//This is the style for the whole details section
#details {
    @include margin;
    font-size: 1.2em;
    width: 80%;
    margin: 0 auto;

    p {
        text-align: center;
        padding: 20px 0;
    }
}