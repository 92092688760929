//This is the style for the whole welcome section
#home {
    @include section-css;
    .container {
        display: flex;
        justify-content: space-around;
        font-size: 1.5em;

        .text-container {
            .name-cont {
                padding: 30px;
            }
            p {
                padding: 30px;
            }
            width: 60%;
        }
        .image-container {
            display: flex;
            justify-content: center;
            width: 40%;
            border-radius: 50%;
            img {
                object-fit: cover;
                border-radius: 50%;
                width: 300px;
                height: 300px;
                border: 10px solid $primary-opac;
                //outline: 10px solid $primary-opac;
            }
        }
    }
}
.light {
    #home {
        .container {
            .text-container {
                .name-cont {
                    span {
                        color: $grey;
                    }
                }
            }
            .image-container {
                img {
                    border: 10px solid $primary-opac-lightMode;
                }
            }
        }
    }
}

//This is the style for the whole skills section
#skills {
    @include section-css;
    flex-direction: column;
    h3 {
        text-align: center;
        color: $white-opac;
        margin: 20px;
    }

    .container {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        width: 70%;

        img {
            margin: 10px;
            width: auto;
            height: 90px;
        }

        img:hover {
            transform: scale(1.15);
            transition: 0.6s;
        }
    }
}
.light {
    #skills {
        h3 {
            color: $grey;
        }
    }
}
