//importing the css components created in the _components.scss
@import "components";
@import "social";
@import "contact";
@import "details";
@import "education";
@import "navbar";
@import "project";
@import "welcome";
@import "footer";
@import "responsiveness";
@import "githubproject";

//Global Settings
* {
    padding: 0;
    margin: 0;
    text-align: left;
}
html {
    scroll-behavior: smooth;
}
.container {
    margin: 0 auto;
    width: 80%;
}
body {
    font-family: $font-family;
    overflow-x: hidden;
}
.dark {
    background: black;
    color: $white;
}
.light {
    background: $white;
    color: black;
    .social-media {
        background-color: $primary-opac-lightMode !important;
    }
}
a {
    text-decoration: none;
    color: $white;
    border-radius: 10px;
}
h2 {
    @include padding;
    margin: 30px 0;
    text-align: center;
    font-size: 3em;
    white-space: wrap;
}
h3 {
    font-size: 1.5em;
    padding: 20px;
}
