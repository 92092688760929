//changing element attributes for screen smaller than 800px to enable responsiveness
@media screen and (max-width: 800px) {
    .container {
        align-items: center;
        width: 100% !important;
    }

    #contact {
        width: 40% !important;
    }
    #education {
        .container {
            flex-direction: column !important;
            .left-container {
                border-right: none;
                width: 100% !important;
            }
            .right-container {
                border-left: none;
                width: 100% !important;
            }
        }
    }
    #home {
        height: fit-content;
        margin-top: 25px;
        .container {
            flex-direction: column-reverse;
        }
        .text-container {
            width: 100% !important;
        }
        .image-container {
            width: 100% !important;
            border-radius: 50%;
            padding-bottom: 50px;
            padding-top: 50px;
        }
    }
    #experience {
        .exp-container {
            display: flex;
            align-items: center;
            width: 90% !important;
            flex-direction: column;
        }
        .text-container {
            width: 90% !important;
        }
        img {
            width: 200px !important;
        }
    }
    #cta {
        justify-content: center;
    }
    .social-media {
        background-color: $primary-opac !important;
        margin-top: 80px;
        padding: 12px 0;
        ul {
            display: flex !important;
            flex-direction: row !important;
            justify-content: center;
        }
        width: 100% !important;
        position: relative;
        border-radius: 0;
    }
    nav {
        display: flex;
        justify-content: center !important;
    }
    .navbar {
        right: unset !important;
        top: 0 !important;
        height: 60px !important;
        width: 95% !important;
        justify-content: center !important;
    }
    .nav-container {
        width: 20% !important;
        margin: 5px;
        a {
            height: 30% !important;
        }
    }
    #footer {
        margin-top: 0;
    }
    #project {
        .container {
            .project-container {
                .project-slide {
                    width: 85% ;
                    height: 83% ;
                           .img-grid {
                    img {
                        aspect-ratio: auto;
                    }
                }
                }
         
            }
        }
    }
}

//changing element attributes for screen smaller than 540px to enable responsiveness
@media screen and (max-width: 550px) {
    #github-project {
        @include mobile-margin;
        margin: 0 auto !important;
        .container {
            margin: 0 auto !important;
            flex-direction: column !important;
            justify-content: center !important;
        }
    }
    #skills {
        @include mobile-margin;
        img {
            margin: 10px !important;
            width: auto !important;
            height: 55px !important;
        }
    }
    #contact {
        @include mobile-margin;
        position: relative !important;
        background: transparent !important;
        width: 90% !important;
        .exit-box {
            visibility: hidden !important;
        }
        margin: 0 auto !important;
    }
    .light {
        #contact {
            input,
            textarea {
                background: rgba(189, 189, 189, 0.744);
            }
        }

    }
    .navbar {
        height: 45px !important;
        margin: 5px !important;
    }
    .nav-container {
        height: 45px !important;
        width: 45px !important;
        font-size: 0.7em !important;
    }
    body {
        font-size: 0.9em !important;
    }
    #home {
        img {
            width: 270px !important;
            height: 270px !important;
        }
    }
    #details,
    #education,
    #contact {
        @include mobile-margin;
    }
    #details {
        width: 100% !important;
        font-size: 1.1em;
        .container {
            width: 90% !important;
        }
    }
    #project {
        @include mobile-margin;
        h5{
            font-size: .7em;
        }
    }
}
