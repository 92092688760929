/*This page contains reusable css components*/

//Importing the font family
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

// padding/margin
@mixin padding {
    padding: 30px 0;
}
@mixin margin {
    margin: 250px 0;
}

@mixin mobile-margin {
    margin: 100px auto !important;
}

@mixin section-css {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
//fonts-family
$font-family: "Roboto Mono", monospace;

//Colors
$primary: rgb(30, 143, 255);
$primary-opac: rgba(30, 143, 255, 0.478);
$white: rgb(229, 229, 229);
$white-opac: rgba(255, 255, 255, 0.455);
$grey: rgb(90, 90, 90);
$primary-lightMode: rgb(30, 100, 255);
$primary-opac-lightMode: rgba(30, 100, 255, 0.478);

//font-colors
.opac-font {
    color: $white-opac;
}
.primary-font {
    color: $primary;
}
.light {
    .primary-font {
        color: $primary-lightMode;
    }
}
.error {
    color: red;
}
.success {
    color: rgb(1, 165, 1);
}
.JavaScript {
    color: rgb(240, 219, 79);
}
.light {
    .JavaScript {
        color: rgb(255, 234, 98);
    }
}

.Java {
    color: rgb(248, 152, 32);
}
.PHP {
    color: rgb(137, 147, 190);
}

.HTML {
    color: rgb(227, 76, 38);
}

.SCSS {
    color: rgb(204, 102, 153);
}
.CSS {
    color: rgb(38, 77, 228);
}
textarea {
    resize: none;
}
.mode-btn-container {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all ease-in-out 0.2s;
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1.5px solid $primary;
        color: $primary;
        font-size: 1.4em;
        transition: all ease-in-out 0.2s;
    }
    button:hover {
        cursor: pointer;
    }
}
.light {
    .mode-btn-container {
        button {
            border: 1.5px solid $primary-lightMode;
            color: $primary-lightMode;
        }
    }
}
.sun {
    button {
        background-color: $white;
    }
    button:hover {
        background-color: black;
    }
}

.moon {
    button {
        background-color: black;
    }
    button:hover {
        background-color: $white;
    }
}

#contact-button {
    width: 80px;
    height: 80px;
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    bottom: 0;
    background: $white;
    border-radius: 50%;

    .message-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: none;
        background-color: transparent;
        font-size: 2em;
        color: $primary;
        border: 2px solid $primary;
    }

    .message-btn:hover {
        outline: 4px solid $white;
        background-color: $primary;
        color: $white;
        transition: 0.5s;
        cursor: pointer;
    }
}
.light {
    #contact-button {
        .message-btn {
            color: $primary-lightMode;
            border: 2px solid $primary-lightMode;
        }
        .message-btn:hover {
            outline: 4px solid $white;
            background-color: $primary-lightMode;
            color: $white;
            transition: 0.5s;
            cursor: pointer;
        }
    }
}
