#contact {
    .container {
        margin: 0 auto;
        width: 90%;
    }
    .success-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            padding: 30px;
            width: 60px;
            height: 60px;
        }
    }

    h2 {
        padding: 0;
        font-size: 2rem;
    }
    height: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    font-size: 1em;
    background-color: $primary-opac;
    width: 30%;

    .form-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        label {
            color: $white;
        }
        input {
            background-color: $white-opac;
            font-size: 1em;
            width: 80%;
            height: 40px;
            margin: 20px 0;
            border-radius: 5px;
            border: none;
        }
        textarea {
            background-color: $white-opac;
            margin: 20px 0;
            font-size: 1.2em;
            width: 80%;
            min-height: 300px;
            border-radius: 5px;
            border: none;
        }
        input:focus,
        textarea:focus,
        input:hover,
        textarea:hover {
            background-color: $white;
            outline: $primary 2px solid;
            transition: 0.3s;
        }
        .btn {
            margin-top: 30px;
            width: 50%;
            text-align: center;
            font-size: 1em;
            border: 2px solid $white;
            border-radius: 8px;
            color: $white;
            height: 50px;
            background-color: $primary;
        }
        .btn:hover {
            cursor: pointer;
            background-color: $white;
            color: $primary;
            transition: 0.5s;
        }
    }
}

.exit-box {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    color: $white;

    .exit-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 20px;
        border-radius: 0;
        border: none;
        background-color: transparent;
        font-size: 2.5em;
        color: $white;
    }

    .exit-btn:hover {
        color: $primary;
        transition: 0.5s;
        cursor: pointer;
    }
}
.light {
    #contact {
        background-color: $primary-opac-lightMode;
        .form-group {
            textarea:hover {
                outline: $primary-lightMode 2px solid;
            }
            .btn {
                background-color: $primary-lightMode !important;
            }
            .btn:hover {
                color: $primary-lightMode;
            }
        }
    }
}
.exit-box {
    .exit-btn:hover {
        color: $primary-lightMode;
    }
}
